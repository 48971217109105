body {
  margin: 0;
  padding: 0;
  font-family: Graphik, 'Segoe UI', 'Helvetica', sans-serif;   
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #a5a6a7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.lolaRow {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  margin: 40px 0;
}
.lolaSeats {
  width: 30px;
  height: 30px;
  margin: 5px;
  text-align: center;
  cursor: default;  
}
.lolaSelect {
  cursor: pointer;
}
.lolaSeats[data-color=blue] {
  background-color: #1b60e8;
}
.lolaSeats[data-color=grey] {
  background-color: #dcdddf;
}
.lolaContained {
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
}
